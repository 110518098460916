const localeKey = [
    'selectAll',
    'selectAllSearchResults',
    'searchOoo',
    'blanks',
    'noMatches',

    'filterOoo',
    'equals',
    'notEqual',

    // Number Filter
    'lessThan',
    'greaterThan',
    'lessThanOrEqual',
    'greaterThanOrEqual',
    'inRange',
    'inRangeStart',
    'inRangeEnd',

    // Text Filter
    'contains',
    'notContains',
    'startsWith',
    'endsWith',

    // Date Filter
    'dateFormatOoo',

    // Filter Conditions
    'andCondition',
    'orCondition',

    // Filter Buttons
    'applyFilter',
    'resetFilter',
    'clearFilter',
    'cancelFilter' ,

    // Side Bar
    'columns',
    'filters',

    // columns tool panel
    'pivotMode',
    'groups',
    'rowGroupColumnsEmptyMessage',
    'values',
    'valueColumnsEmptyMessage',
    'pivots',
    'pivotColumnsEmptyMessage',

    // Header of the Default Group Column
    'group',

    // Other
    'loadingOoo',
    'noRowsToShow',
    'enabled',

    // Menu
    'pinColumn',
    'pinLeft',
    'pinRight',
    'noPin',
    'valueAggregation',
    'autosizeThiscolumn',
    'autosizeAllColumns',
    'groupBy' ,
    'ungroupBy',
    'resetColumns',
    'expandAll',
    'collapseAll',
    'copy',
    'ctrlC',
    'copyWithHeaders',
    'paste',
    'ctrlV',
    'export',
    'csvExport',
    'excelExport',
    'excelXmlExport',

    // Enterprise Menu Aggregation and Status Bar
    'sum',
    'min',
    'max',
    'none',
    'count',
    'avg',
    'filteredRows',
    'selectedRows',
    'totalRows',
    'totalAndFilteredRows',
    'page',
    'more',
    'to',
    'of',
    'next',
    'last',
    'first',
    'previous',

    // Enterprise Menu (Charts)
    'pivotChartAndPivotMode',
    'pivotChart',
    'chartRange',

    'columnChart',
    'groupedColumn',
    'stackedColumn',
    'normalizedColumn',

    'barChart',
    'groupedBar',
    'stackedBar',
    'normalizedBar',

    'pieChart',
    'pie',
    'doughnut',

    'line',

    'xyChart',
    'scatter',
    'bubble',

    'areaChart',
    'area',
    'stackedArea',
    'normalizedArea',

    'histogramChart',

    // Charts
    'pivotChartTitle',
    'rangeChartTitle',
    'settings',
    'data',
    'format' ,
    'categories',
    'defaultCategory',
    'series',
    'xyValues',
    'paired',
    'axis',
    'navigator',
    'color',
    'thickness',
    'xType',
    'automatic',
    'category',
    'number',
    'time',
    'xRotation' ,
    'yRotation' ,
    'ticks',
    'width',
    'height',
    'length',
    'padding',
    'spacing',
    'chart',
    'title',
    'titlePlaceholder',
    'background',
    'font',
    'top',
    'right',
    'bottom' ,
    'left',
    'labels',
    'size',
    'minSize',
    'maxSize',
    'legend',
    'position',
    'markerSize',
    'markerStroke' ,
    'markerPadding' ,
    'itemSpacing',
    'itemPaddingX',
    'itemPaddingY',
    'layoutHorizontalSpacing',
    'layoutVerticalSpacing',
    'strokeWidth',
    'offset',
    'offsets',
    'tooltips',
    'callout',
    'markers',
    'shadow',
    'blur',
    'xOffset',
    'yOffset',
    'lineWidth',
    'normal',
    'bold',
    'italic',
    'boldItalic',
    'predefined',
    'fillOpacity' ,
    'strokeOpacity',
    'histogramBinCount',
    'columnGroup',
    'barGroup',
    'pieGroup',
    'lineGroup',
    'scatterGroup',
    'areaGroup',
    'histogramGroup',
    'groupedColumnTooltip',
    'stackedColumnTooltip',
    'normalizedColumnTooltip',
    'groupedBarTooltip',
    'stackedBarTooltip',
    'normalizedBarTooltip',
    'pieTooltip',
    'doughnutTooltip',
    'lineTooltip',
    'groupedAreaTooltip',
    'stackedAreaTooltip',
    'normalizedAreaTooltip',
    'scatterTooltip',
    'bubbleTooltip',
    'histogramTooltip',
    'noDataToChart',
    'pivotChartRequiresPivotMode',
];

export { 
    localeKey
};