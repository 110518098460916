<template>
    <div class="container-fluid">
        <loading-overlay :active="loadingOverlay" :is-full-page="true" loader="bars" />
        <CRow class="mb-3">
            <CCol sm="8" lg="8" xl="8" class="d-flex align-items-center justify-content-start">
                <h6 class="mt-2"><CIcon name='cil-list' size='lg' class="titulo-icono" /> {{ $t('label.BinsReturned') }}</h6>
            </CCol>
            <CCol sm="4" lg="4" xl="4" class="d-flex align-items-center justify-content-end">
                <CButton
                    color="excel"
                    class="mr-1" 
                    size="sm"
                    @click="onBtnExport(true)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; XSLX
                </CButton>
                <CButton
                    color="watch"
                    size="sm"  
                    @click="onBtnExport(false)"
                >
                    <CIcon name="cil-file-excel"/>&nbsp; CSV
                </CButton>
            </CCol>
        </CRow>
        <CRow>
            <CCol sm="12" lg="3" xl="3" class="center-field">
                <CInput
                    :label="$t('label.filter')"
                    :horizontal="{ label: 'col-sm-11 col-lg-3 col-xl-3 text-right', input: 'col-sm-11 col-lg-9 col-xl-9 px-0'}"
                    :placeholder="$t('label.enterSearch')" 
                    @keyup="onQuickFilterChanged($event.target.value)" 
                    v-model="search"           
                >
                </CInput>  
            </CCol>
            <CCol sm="12" lg="5" xl="5" class="center-field">
            </CCol>
            <CCol sm="12" lg="4" xl="4" class="justify-content-end">
                <CSelect
                    :horizontal="{ label: 'col-sm-11 col-lg-8 col-xl-8 text-right', input: 'col-sm-11 col-lg-4 col-xl-4 px-0'}"
                    :label="$t('label.recordsPerPage')"
                    @change="onPageSizeChanged"
                    :options="[50,100,500,1000]"
                    required 
                    v-model="pageSize"
                >
                </CSelect>
            </CCol>

        </CRow>
        <CRow>
            <CCol sm="11" lg="12" xl="12" class=" mb-2">
                <ag-grid-vue
                    v-if="showGrid"
                    style="width: 100%; height: 100vh;"
                    class="ag-theme-alpine"
                    :getRowStyle="getRowStyle"
                    :getRowClass="getRowClass"
                    :gridOptions="gridOptions"
                    :defaultColDef="defaultColDef"
                    :localeText="localeText"
                    :columnDefs="columnDefs"
                    :rowData="RowData"
                    :suppressRowClickSelection="true"
                    :groupSelectsChildren="true"
                    :enableRangeSelection="true"
                    :pagination="true"
                    :paginationPageSize="paginationPageSize"
                    :paginationNumberFormatter="paginationNumberFormatter"
                    @grid-ready="onGridReady"
                >
                </ag-grid-vue>
            </CCol>
        </CRow>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import { formatMilDecimal } from '@/_validations/validacionEspeciales';
    import ReportesVisitas from '@/_mixins/reportesVisitas';
    import AgGrid from '@/_mixins/ag-grid';
    import { DateFormater } from '@/_helpers/funciones';

    //Data
    function data() {
        return {
            gridOptions: null,
            paginationPageSize: null,
            paginationNumberFormatter: null,

   
            rows: [],
            formatedItems: [],
            filtroOptions:{
                //weigth
                weigth:null,
                vgm:null,
                //longitud
                length:null,
                heigth:null,    
            },
            defaultColDef: null,
            AllgridApi:null,
            AllgridColumnApi:null,
            gridApi: null,
            columnApi: null,
            modalColumn:false,
            loadingOverlay:false,
            dataWeight:[],
            dataLongitud:[],
            columnsOption:{
                size:true,
                pod:true,
                weigth:true,
                class:true,
                setting:true,
                height:true,
                status:true,
                delivery:false,
                opt_port:true,
                FgConfirmed: true,
                IsoCode: true,
                Seals: true,
                DeliveryPort:true,
                Status: true,
                TpCargoDetailCode:true,
                LoadPort:true,
                DischargePort:true,
                ShippingLineCode:true,
                ShippingLineName:true,
                ImdgCode:true,
            },

            disabledFilterByPreference: false,
            nulo:'', 
            pageSize:'',
            search: '',
            labelUnitMeasure: 'TON',
        };
    }
    //mounted
    function beforeMount() {
        this.gridOptions = {};
        this.defaultColDef = {
            editable: false,
            resizable: true,
            sortable: true,
            minWidth: 150,
            flex: 1,
        };
        this.paginationPageSize = 100;
        this.paginationNumberFormatter = (params) => {
            return params.value.toLocaleString();
        };
    }

    //methods
    function onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
    }

    function getRowClass(params) {
        if (params.node.data.section=="header") {
            return 'bg-secondary font-weight-bold bg-gradient'
        }
        return 'bg-gradient';
    }

    function getRowStyle(params) {
        return { background: params.node.data.ColorStatus };
    }

    function onQuickFilterChanged(value) {
        this.gridApi.setQuickFilter(value);
    }

    function onPageSizeChanged(event) {
        this.gridApi.paginationSetPageSize(parseInt(event.target.value));
    }

    async function onBtnExport(valor) {
        this.loadingOverlay = true;
        let currentDate = new Date();
        let rowData = [];
        let rowDataFormated = [];
        let valores = []; 
        valores[0] = this.$t('label.printed')+": "+DateFormater.formatTimeZoneToDateTime(currentDate);
        valores[1] = this.$t('label.user')+": "+this.user.UserName+(this.user.UserLastName ? ' '+this.user.UserLastName : '');
        this.gridApi.forEachNodeAfterFilterAndSort(node => rowData.push(node.data));
        
        rowDataFormated.push({
            section: 'header',
            FirstFullWeight: this.$t('label.FirstFullWeight'),
            LastFullWeight: this.$t('label.LastFullWeight'),
            MayorFullWeight: this.$t('label.MayorFullWeight'),
        });
        for (let i = 0; i < rowData.length; i++) {
            if(rowData[i].section != 'header'){
                rowDataFormated.push({
                    Nro: rowData[i].Nro,
                    OrderCode: rowData[i].OrderCode,
                    OrderDate: rowData[i].OrderDate,
                    CarrierClientName: rowData[i].CarrierClientName,
                    YardName: rowData[i].YardName,
                    YardClientName: rowData[i].YardClientName,
                    LicensePlate: rowData[i].LicensePlate,
                    ImplementAlias: rowData[i].ImplementAlias,
                    TareWeight: rowData[i].TareWeight,
                    TransactionBegin: rowData[i].TransactionBegin,
                    TransactionFinish: rowData[i].TransactionFinish,
                        
                    FirstFullWeight: rowData[i].FirstFullWeight,
                    FirstFullTruckWeghingScale: rowData[i].FirstFullTruckWeghingScale,
                    FirstFullDate: rowData[i].FirstFullDate,

                    LastFullWeight: rowData[i].LastFullWeight,
                    LastFullTruckWeghingScale: rowData[i].LastFullTruckWeghingScale,
                    LastFullDate: rowData[i].LastFullDate,

                    MayorFullWeight: rowData[i].MayorFullWeight,
                    MayorFullTruckWeghingScale: rowData[i].MayorFullTruckWeghingScale,
                    MayorFullDate: rowData[i].MayorFullDate,

                    DiferenceWeight: rowData[i].DiferenceWeight,
                    ReturnedDate: rowData[i].ReturnedDate,
                    CraneAlias: rowData[i].CraneAlias,
                    VesselHoldName: rowData[i].VesselHoldName,
                    Observation: rowData[i].Observation,
                    TransaLogin: rowData[i].TransaLogin,
                    DeviceCode: rowData[i].DeviceCode,
                    OrderStatusDs: rowData[i].OrderStatusDs,
                });
            }  
        }

        if(this.nulo!="null" && this.formatedItems.length !== 0) {
            await this.getOrdersExcel(rowDataFormated,this.$t('label.BinsReturned'),valor, valores);
        }else{
            this.$notify({  
                group: 'container',
                title: '¡Error!',
                text: this.$t('label.noRecordsAvailbleReport'),
                type: "error" 
            });
        }
        this.loadingOverlay = false;
        
    }

    async function getOrdersList () {
        this.loadingOverlay = true;
        this.formatedItems = [];
        let listado = Array;
     
        let ShipmentOrderJson = [{
            VisitId: this.Visit,
        }];
        await this.$http.post("VisitOrderImplementReturned-list", ShipmentOrderJson, { root: 'ShipmentOrderJson' })
        .then(response => {
            listado = [...response.data.data];
            let nulo = String(listado[0].Json);
            if (nulo=="null" || listado ==''){
                return false;
            }
            this.formatedItems.push({
                section: 'header',
                FirstFullWeight: this.$t('label.FirstFullWeight'),
                LastFullWeight: this.$t('label.LastFullWeight'),
                MayorFullWeight: this.$t('label.MayorFullWeight'),
            })
            for (let i=0; i < listado.length; i++) {
                this.formatedItems.push({
                    Nro: listado[i].Nro ? listado[i].Nro : '',
                    OrderCode: listado[i].OrderCode ? listado[i].OrderCode : '',
                    OrderDate: listado[i].OrderDate ?  DateFormater.formatDateTimeWithSlash(listado[i].OrderDate) : '',
                    CarrierClientName: listado[i].CarrierClientName ? listado[i].CarrierClientName : '',
                    YardName: listado[i].YardName ? listado[i].YardName : '',
                    YardClientName: listado[i].YardClientName ? listado[i].YardClientName : '',
                    LicensePlate: listado[i].LicensePlate ? listado[i].LicensePlate : '',
                    ImplementAlias: listado[i].ImplementAlias ? listado[i].ImplementAlias : '',
                    TareWeight: listado[i].TareWeight ? listado[i].TareWeight : 0,
                    TareWeightOrigin: listado[i].TareWeight ? formatMilDecimal(parseFloat(listado[i].TareWeight).toFixed(2)) : '0,00',
                    TransactionBegin: listado[i].TransactionBegin ? DateFormater.formatDateTimeWithSlash(listado[i].TransactionBegin) : '',
                    TransactionFinish: listado[i].TransactionFinish ? DateFormater.formatDateTimeWithSlash(listado[i].TransactionFinish) : '',
                    
                    FirstFullWeightOrigin: listado[i].FirstFullWeightJson.length != 0 ? formatMilDecimal(parseFloat(listado[i].FirstFullWeightJson[0].Weight).toFixed(2)) : '0,00',
                    FirstFullWeight: listado[i].FirstFullWeightJson.length != 0 ? listado[i].FirstFullWeightJson[0].Weight : 0,
                    FirstFullTruckWeghingScale: listado[i].FirstFullWeightJson.length != 0 ? listado[i].FirstFullWeightJson[0].TruckWeghingScaleName : '',
                    FirstFullDate: listado[i].FirstFullWeightJson.length != 0 ? DateFormater.formatDateTimeWithSlash(listado[i].FirstFullWeightJson[0].TransactionDate) : '',

                    LastFullWeightOrigin: listado[i].LastFullWeightJson.length != 0 ? formatMilDecimal(parseFloat(listado[i].LastFullWeightJson[0].Weight).toFixed(2)) : '0,00',
                    LastFullWeight: listado[i].LastFullWeightJson.length != 0 ? listado[i].LastFullWeightJson[0].Weight : 0,
                    LastFullTruckWeghingScale: listado[i].LastFullWeightJson.length != 0 ? listado[i].LastFullWeightJson[0].TruckWeghingScaleName : '',
                    LastFullDate: listado[i].LastFullWeightJson.length != 0 ? DateFormater.formatDateTimeWithSlash(listado[i].LastFullWeightJson[0].TransactionDate) : '',

                    MayorFullWeightOrigin: listado[i].MayorFullWeightJson.length != 0 ? formatMilDecimal(parseFloat(listado[i].MayorFullWeightJson[0].Weight).toFixed(2)) : '0,00',
                    MayorFullWeight: listado[i].MayorFullWeightJson.length != 0 ? listado[i].MayorFullWeightJson[0].Weight : 0,
                    MayorFullTruckWeghingScale: listado[i].MayorFullWeightJson.length != 0 ? listado[i].MayorFullWeightJson[0].TruckWeghingScaleName : '',
                    MayorFullDate: listado[i].MayorFullWeightJson.length != 0 ? DateFormater.formatDateTimeWithSlash(listado[i].MayorFullWeightJson[0].TransactionDate) : '',

                    DiferenceWeightOrigin: listado[i].DiferenceWeight ? formatMilDecimal(parseFloat(listado[i].DiferenceWeight).toFixed(2)) : '0,00',
                    DiferenceWeight: listado[i].DiferenceWeight ? listado[i].DiferenceWeight : 0,
                    ReturnedDate: listado[i].ReturnedDate ? DateFormater.formatDateTimeWithSlash(listado[i].ReturnedDate) : '',
                    CraneAlias: listado[i].CraneAlias ? listado[i].CraneAlias : '',
                    VesselHoldName: listado[i].VesselHoldName ? listado[i].VesselHoldName : '',
                    Observation: listado[i].Observation ? listado[i].Observation : '',
                    TransaLogin: listado[i].TransaLogin ? listado[i].TransaLogin : '',
                    DeviceCode: listado[i].DeviceCode ? listado[i].DeviceCode : '',
                    OrderStatusDsEn: listado[i].OrderStatusDsEn,
                    OrderStatusDsEs: listado[i].OrderStatusDsEs,
                    ColorStatus: listado[i].ColorStatus ? listado[i].ColorStatus : '',
                })
            }
        }).catch( err => {
            this.$notify({
            group: 'container',
            title: '¡Error!',
            text: err,
            type: "error"
            });
        })
        .finally(() => {
            this.loadingOverlay = false;
        });
    }

    async function refreshComponent() {
        this.search="";
        this.onQuickFilterChanged(this.search);
        this.disabledFilterByPreference= false;
        this.pageSize="";
        this.gridApi.paginationSetPageSize(parseInt(50));
        this.getOrdersList();
    }

    //computed
    function RowData(){
        if(this.formatedItems.length != 0){
            let _lang = this.$i18n.locale;
            return this.formatedItems.map((item) => {
                return {
                    ...item,
                    OrderStatusDs: _lang=='en'? item.OrderStatusDsEn : item.OrderStatusDsEs,
                };
            });
        }else{
            return [];
        }
    }

    function columnDefs(){
        let columnDefs = [
                {
                    field: "Nro",
                    headerName: "#",
                    filter: 'agNumberColumnFilter',
                    //lockVisible: true,
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 70,
                    minWidth: 70,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderCode",
                    headerName: this.$t('label.order'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 180,
                    minWidth: 180,
                   // pinned: 'left',
                    cellClass: 'gb-cell text-color-negrita',
                    //checkboxSelection: true, 
                },
                {
                    field: "OrderDate",
                    headerName: this.$t('label.orderDate'),
                    //lockVisible: true,
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    lockPosition: true,
                    suppressMovable: true,
                    width: 180,
                    minWidth: 180,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                    //checkboxSelection: true, 
                },
                {
                    field: "CarrierClientName",
                    headerName: this.$t('label.transport'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "LicensePlate",
                    headerName: this.$t('label.vehicle'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 150,
                    minWidth: 150,
                   // pinned: 'left',
                    cellClass: 'gb-cell',
                },
                {
                    field: "ImplementAlias",
                    headerName: this.$t('label.Gamela'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 180,
                    minWidth: 180,
                    cellClass: 'gb-cell',
                    //pinned: 'left'
                },
                {
                    field: "YardName",
                    headerName: this.$t('label.yard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "YardClientName",
                    headerName: this.$t('label.clientYard'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    width: 180,
                    minWidth: 180,
                },
                {
                    field: "TareWeight",
                    headerName: this.$t('label.Taraweight')+' (KGM)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                    filter: 'agNumberColumnFilter',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        if(Header(params)){
                            return '';
                        }else{
                            return variable;
                        }
    
                    },
                }, 
                {
                    field: "TransactionBegin",
                    headerName: this.$t('label.inicio'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransactionFinish",
                    headerName: this.$t('label.fin'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 150,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },   
                {
                    field: "FirstFullWeight",
                    headerName: this.$t('label.weight')+' (TON)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        if(params.value == this.$t('label.FirstFullWeight')){
                            return this.$t('label.FirstFullWeight');
                        }else{
                            return variable;
                        }
                    },
                    colSpan: (params) => {
                        if (Header(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (Header(params)) {
                            return 'center-cell-especial OrderStatusAdd text-white';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "FirstFullTruckWeghingScale",
                    headerName: this.$t('label.truckScale'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "FirstFullDate",
                    headerName: this.$t('label.transactionDate'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    width: 200,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "LastFullWeight",
                    headerName: this.$t('label.weight')+' (TON)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        if(params.value == this.$t('label.LastFullWeight')){
                            return this.$t('label.LastFullWeight');
                        }else{
                            return variable;
                        }
                    },
                    colSpan: (params) => {
                        if (Header(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (Header(params)) {
                            return 'center-cell-especial legend-color-1 text-white';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "LastFullTruckWeghingScale",
                    headerName: this.$t('label.truckScale'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "LastFullDate",
                    headerName: this.$t('label.transactionDate'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    width: 200,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "MayorFullWeight",
                    headerName: this.$t('label.weight')+' (TON)',
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    filter: 'agNumberColumnFilter',
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        if(params.value == this.$t('label.MayorFullWeight')){
                            return this.$t('label.MayorFullWeight');
                        }else{
                            return variable;
                        }
                    },
                    colSpan: (params) => {
                        if (Header(params)) {
                            return 3;
                        } else {
                            return 1;
                        }
                    },
                    cellClass: (params) => {
                        if (Header(params)) {
                            return 'center-cell-especial legend-color-2 text-white';
                        } else {
                            return 'center-cell-especial';
                        }
                    },
                },
                {
                    field: "MayorFullTruckWeghingScale",
                    headerName: this.$t('label.truckScale'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "MayorFullDate",
                    headerName: this.$t('label.transactionDate'),
                    headerClass: 'center-cell-especial',
                   // sortable: true,
                    lockPosition: true,
                    width: 200,
                    minWidth: 200,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DiferenceWeight",
                    headerName: this.$t('label.DifferenceWeight'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    width: 220,
                    minWidth: 220,
                    cellRenderer: params => {
                        let variable = 0.00;
                        if(params.value !== null && params.value !== '' ){    
                            let newValue =  Number.parseFloat(params.value).toFixed(2);
                            variable = formatMilDecimal(newValue);
                        }
                        if(Header(params)){
                            return '';
                        }else{
                            return variable;
                        }
                    },
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "ReturnedDate",
                    headerName: this.$t('label.ReturnedDate'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    minWidth: 180,
                    //pinned: 'left',
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "CraneAlias",
                    headerName: this.$t('label.crane'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "VesselHoldName",
                    headerName: this.$t('label.hold'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "Observation",
                    headerName: this.$t('label.observation'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "TransaLogin",
                    headerName: this.$t('label.user'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "DeviceCode",
                    headerName: this.$t('label.device'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    suppressMovable: true,
                    lockPosition: true,
                    cellClass: 'center-cell-especial',
                },
                {
                    field: "OrderStatusDs",
                    headerName: this.$t('label.status'),
                    filter: 'agTextColumnFilter',
                    headerClass: 'center-cell-especial',
                    //sortable: true,
                    lockPosition: true,
                    minWidth: 180,
                    cellClass: 'center-cell-especial text-color-negrita',
                },
            ]
            return columnDefs;
    }

    function Header(params) {
        return params.data.section === 'header';
    }

    export default {
        name: "bins-returned",
        data,
        beforeMount,
        props: ['value'],
        mixins: [ReportesVisitas, AgGrid],
        methods:{
            onGridReady,
            onBtnExport,
            getOrdersList,
            onQuickFilterChanged,
            onPageSizeChanged,
            getRowStyle,
            getRowClass,
            refreshComponent,
        },
        computed:{
            RowData,
            Header,
            columnDefs,
            ...mapState({
                user: state => state.auth.user,
                Visit: state => state.visitas.VisitId,
                dropItemReport: state => state.visitas.dropItemReport,  
                itinerarySelected: state => state.visitas.itinerarySelected,
            }),
        },

        watch:{
            dropItemReport: async function (newValue) {
                if (newValue==66 && this.Visit != '') {
                    await this.refreshComponent();
                }
            },
            showGrid: function (val) {
                if (val) {
                    this.loadingOverlay = false;
                } else {
                    this.loadingOverlay = true;
                }
            }
        }
    };
</script>
<style src="@/pages/visita/tabs/reporte/css/reporte.css"></style>